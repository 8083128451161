import { QuickAssist } from "./QuickAssistContext";

export const demo: QuickAssist[] = [
  {
    value: "creating-a-project",
    heading: "Creating a Project",
    body: "This is a demo for creating a project.",
    steps: [
      {
        target: "[data-quick-assist-id=header-projects-button]",
        content:
          "Click on the 'Projects' button to navigate to the projects page.",
        disableBeacon: true,
        hideCloseButton: true,
      },
      {
        target: "[data-quick-assist-id=create-project]",
        content:
          "Click on the 'Create Project' button to start creating a project",
        disableBeacon: true,
        hideCloseButton: true,
      },
      {
        target: "[data-quick-assist-id=project-title]",
        content: "Enter a title for the project",
        disableBeacon: true,
      },
      {
        target: "[data-quick-assist-id=project-mixdown]",
        content:
          "You can drag and drop / click upload to add your latest mixdown for the project here",
        disableBeacon: true,
      },
      {
        target: "[data-quick-assist-id=project-permalink]",
        content:
          "Give your project a unique URL. This will be used to access and share your project",
        disableBeacon: true,
      },
      {
        target: "[data-quick-assist-id=project-create]",
        content: "Click on the 'Create' button to create the project",
        disableBeacon: true,
        hideCloseButton: true,
      },
    ],
  },
  {
    value: "using-a-project",
    heading: "Using a Project",
    body: "This is a demo for using a project.",
    condition: (route) => route.startsWith("/p/"),
    steps: [
      {
        target: "[data-quick-assist-id=project-home]",
        content:
          "The project page is your home base for all things related to your track. Click 'Next' to take a tour of the project page.",
        disableBeacon: true,
      },
      {
        target: "[data-quick-assist-id=project-title]",
        content: "You can change the name of your project here",
        disableBeacon: true,
      },
      {
        target: "[data-quick-assist-id=project-mixdown]",
        content: "This is where you can view and upload your latest mixdown.",
        disableBeacon: true,
      },
      {
        target: "[data-quick-assist-id=copy-permalink]",
        content:
          "You can copy the link to your project here, to share your project with others in style",
        disableBeacon: true,
      },
      {
        target: "[data-quick-assist-id=launch-stemviewer]",
        content:
          "Click on the 'Launch Stem Viewer' button to open the stem viewer",
        disableBeacon: true,
        hideCloseButton: true,
      },
      {
        target: "[data-quick-assist-id=stemviewer]",
        content:
          "The Stem Viewer allows you to view, annotate and share stems with collaborators on your project",
        disableBeacon: true,
      },
      {
        target: "[data-quick-assist-id=sv-upload]",
        content:
          "Click here to upload stems to the stem viewer. You can also drag and drop files onto the Stem Viewer directly",
        disableBeacon: true,
      },
      {
        target: "[data-quick-assist-id=sv-invite-user]",
        content: "Click here to invite collaborators to the project",
        disableBeacon: true,
        hideCloseButton: true,
      },
    ],
  },
  {
    value: "using-tasks",
    heading: "Using Tasks",
    body: "This is a demo for using tasks.",
    condition: (route) => route.startsWith("/p/") && route.endsWith("/tasks"),
    steps: [
      {
        target: "[data-quick-assist-id=create-task]",
        content:
          "Click here to create a new task and assign it to one of your collaborators",
        disableBeacon: true,
      },
    ],
  },
  {
    value: "using-threads",
    heading: "Using Threads",
    body: "This is a demo for using threads.",
    condition: (route) => route.startsWith("/p/") && route.endsWith("/threads"),
    steps: [
      {
        target: "[data-quick-assist-id=project-thread-create]",
        content: "Click here to start a thread about your lyrics, ideas, etc.",
        disableBeacon: true,
      },
    ],
  },
  {
    value: "inviting-collaborators",
    heading: "Inviting Collaborators",
    body: "This is a demo for inviting collaborators.",
    condition: (route) => route.startsWith("/p/") && route.endsWith("/collab"),
    steps: [
      {
        target: "[data-quick-assist-id=project-invite-user]",
        content:
          "Click here to invite new collaborators to your project. You can also generate a shareable link for your project",
        disableBeacon: true,
      },
    ],
  },
  {
    value: "changing-project-settings",
    heading: "Changing Project Settings",
    body: "This is a demo for changing project settings.",
    condition: (route) =>
      route.startsWith("/p/") && route.endsWith("/settings"),
    steps: [
      {
        target: "[data-quick-assist-id=project-permalink]",
        content:
          "You can change the permalink for your project here to share a more aesthetic URL",
        disableBeacon: true,
      },
      {
        target: "[data-quick-assist-id=project-artist]",
        content:
          "You can change the artist name for your project, so it appears correctly when playing the mixdown on media players",
        disableBeacon: true,
      },
      {
        target: "[data-quick-assist-id=project-privacy]",
        content:
          "You can change the privacy settings for your project to control who can access it",
        disableBeacon: true,
      },
      {
        target: "[data-quick-assist-id=project-linked-accounts]",
        content:
          "You can link your social media accounts to your project here, so your fans can follow you through your project page",
        disableBeacon: true,
      },
    ],
  },
  {
    value: "comparing-mixdown-versions",
    heading: "Comparing Mixdown Versions",
    body: "This is a demo for comparing mixdown versions.",
    condition: (route) =>
      route.startsWith("/p/") &&
      !!document.getElementById("mixdown-compare-button"),
    steps: [
      {
        target: "[data-quick-assist-id=mixdown-select]",
        content:
          "Click here to switch between different mixdown versions of your project",
        disableBeacon: true,
      },
      {
        target: "[data-quick-assist-id=mixdown-compare-button]",
        content:
          "Click here to compare your current mixdown version side-by-side with another version",
        disableBeacon: true,
        hideCloseButton: true,
      },
      {
        target: "[data-quick-assist-id=mixdown-compare-item]",
        content: "Choose the version you want to compare.",
        disableBeacon: true,
        hideCloseButton: true,
      },
      {
        target: "[data-quick-assist-id=mixdown-compare]",
        content:
          "Click the play button to start playback. Click on each track to toggle between them.",
        disableBeacon: true,
      },
    ],
  },
];
