import React, { Fragment, useEffect, useState } from "react";
import { ActionIcon, Button } from "@common/components";
import { useMyMarkups } from "../../../requests/project/markup";
import { ChevronDown, Plus } from "tabler-icons-react";
import { uniqBy } from "lodash";
import { TaskBoard, TaskBoardState } from "../components/TaskBoard";
import { Project } from "@server/entities";
import { useTasks } from "../../../contexts/Tasks/TaskContext";
import { AnimatePresence, motion } from "framer-motion";
import { useLocalStorage } from "@mantine/hooks";

export const Tasks: React.FC = () => {
  const [assignedByMe, setAssignedByMe] = useLocalStorage({
    key: "tasks.assignedByMe",
    defaultValue: false,
  });
  const [assignedToMe, setAssignedToMe] = useLocalStorage({
    key: "tasks.assignedToMe",
    defaultValue: false,
  });

  const { data, mutate } = useMyMarkups({
    type: ["task", "markup"],
    withApproved: true,
    limit: 100,
    assignedByMe: assignedByMe ? true : undefined,
    assignedToMe: assignedToMe ? true : undefined,
  });

  const { createTask, mutateTasks } = useTasks();

  const [state, setState] = useState<Array<{
    project: Project;
    state: TaskBoardState;
    collapsed: boolean;
  }> | null>(null);

  // Mutate on task create
  useEffect(() => {
    mutate();
  }, [mutateTasks]);

  useEffect(() => {
    const projects = uniqBy(
      data?.data.map((markup) => markup.project!),
      "id"
    );

    setState(
      projects.map((project) => ({
        collapsed: false,
        project,
        state: {
          columns: [
            {
              status: "pending",
              color: "blue",
              label: "To do",
              markups:
                data?.data.filter(
                  (markup) =>
                    markup.project?.id === project.id &&
                    markup.status === "pending"
                ) || [],
            },
            {
              status: "needs_approval",
              color: "yellow",
              label: "In progress",
              markups:
                data?.data.filter(
                  (markup) =>
                    markup.project?.id === project.id &&
                    markup.status === "needs_approval"
                ) || [],
            },
            {
              status: "approved",
              color: "green",
              label: "Done",
              markups:
                data?.data.filter(
                  (markup) =>
                    markup.project?.id === project.id &&
                    markup.status === "approved"
                ) || [],
            },
          ],
        },
      }))
    );
  }, [data]);

  const handleCollapse = (projectId: string) => {
    setState(
      (prev) =>
        prev?.map((item) =>
          item.project.id === projectId
            ? { ...item, collapsed: !item.collapsed }
            : item
        ) || null
    );
  };

  return (
    <div className="container">
      <div className="flex justify-between">
        <div className="flex gap-2">
          <Button
            data-testid="task-filter-assigned-to-me"
            variant={assignedToMe ? "filled" : "light"}
            color={assignedToMe ? "primary" : "gray"}
            onClick={() => setAssignedToMe((prev) => !prev)}
          >
            Assigned to me
          </Button>

          <Button
            data-testid="task-filter-assigned-by-me"
            variant={assignedByMe ? "filled" : "light"}
            color={assignedByMe ? "primary" : "gray"}
            onClick={() => setAssignedByMe((prev) => !prev)}
          >
            Assigned by me
          </Button>
        </div>

        <Button
          data-testid="create-task"
          leftIcon={<Plus className="w-5 h-5" />}
          onClick={() => createTask()}
        >
          Create task
        </Button>
      </div>

      <div className="my-10">
        {state?.map(({ project, state, collapsed }) => (
          <Fragment key={project.id}>
            <div className="bg-gradient-to-r from-indigo-800 to-pink-700 rounded px-3 py-2 my-4 flex justify-between items-center gap-2">
              <h3 className="text-xs uppercase text-dark-100">
                {project.name}
              </h3>

              <ActionIcon
                size="sm"
                color="white"
                variant="subtle"
                onClick={() => handleCollapse(project.id)}
              >
                <ChevronDown
                  className="w-5 h-5"
                  style={{
                    transform: collapsed ? "rotate(90deg)" : "rotate(0deg)",
                  }}
                />
              </ActionIcon>
            </div>

            <AnimatePresence>
              {!collapsed && (
                <motion.div
                  initial="collapsed"
                  animate="open"
                  exit="collapsed"
                  variants={{
                    open: { opacity: 1, width: "100%", height: "auto" },
                    collapsed: { opacity: 0, width: "100%", height: 0 },
                  }}
                  transition={{ type: "tween", duration: 0.15 }}
                >
                  <TaskBoard state={state} />
                </motion.div>
              )}
            </AnimatePresence>
          </Fragment>
        ))}
      </div>
    </div>
  );
};
