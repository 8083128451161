import React from "react";
import { UploadImageModal } from "@common/components/UploadImageModal";
import { Image } from "@mantine/core";
import { UseAsyncFormReturnType } from "@common/utils/useAsyncForm";
import { UpdateProjectDto } from "@server/modules/project/project/dto";
import { AvatarsGroup, Textarea } from "@common/components";
import { useProject } from "../ProjectContext";

interface ProjectHeaderProps {
  form: UseAsyncFormReturnType<UpdateProjectDto>;
}

export const ProjectHeader: React.FC<ProjectHeaderProps> = ({ form }) => {
  const { project, view } = useProject();

  const handleUpload = async (url) => {
    form.setFieldValue("albumArt", url);
  };

  return (
    <div className="flex-1 flex items-center gap-8">
      {view !== "viewer" ? (
        <UploadImageModal
          targetWidth={128}
          targetHeight={128}
          onUpload={handleUpload}
          projectId={project?.id}
          dataTestId="project-album-art"
        >
          <Image
            width={96}
            height={96}
            radius="md"
            src={form.values.albumArt || "/images/no-album-art.png"}
            withPlaceholder
          />
        </UploadImageModal>
      ) : (
        <Image
          data-testid="project-album-art"
          width={96}
          height={96}
          radius="md"
          src={form.values.albumArt || "/images/no-album-art.png"}
          withPlaceholder
        />
      )}

      <div>
        <Textarea
          data-testid="project-title"
          data-quick-assist-id="project-title"
          {...form.getInputProps("name")}
          classNames={{
            root: "flex-1",
            input: `w-full font-bold text-5xl pl-2 -mx-2 bg-dark-1000 bg-opacity-0 ${
              view !== "viewer"
                ? "transition hover:bg-opacity-100"
                : "border-none"
            }`,
          }}
          styles={{
            input: { border: "1px solid transparent" },
          }}
          placeholder="Untitled"
          autosize
          minRows={1}
          readOnly={view === "viewer"}
        />

        {project && (
          <AvatarsGroup
            noAnimation
            size="sm"
            className="mb-4"
            users={project.collaborators.map((collab) => collab?.user)}
          />
        )}
      </div>
    </div>
  );
};
