import React from "react";
import { useMyMarkups } from "../../../requests/project/markup";
import Skeleton from "@common/components/Skeleton";
import { Avatar, Tooltip } from "@common/components";
import { MarkupStatusBadge } from "../../stemviewer/view/markup/components/MarkupStatusBadge";
import { Badge, ScrollArea } from "@mantine/core";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import { QueryMarkupDto } from "@server/modules/project/markup/dto/query-markup.dto";

export const MyMarkups: React.FC<
  Pick<QueryMarkupDto, "assignedByMe" | "assignedToMe">
> = (query) => {
  const { data } = useMyMarkups({
    type: "markup",
    ...query,
  });

  return (
    <ScrollArea.Autosize
      maxHeight={500}
      offsetScrollbars
      data-quick-assist-id="my-markups"
    >
      <div className="flex flex-col">
        {data ? (
          data?.data.length > 0 ? (
            data?.data.map((markup) => (
              <Link
                data-testid={`${
                  query.assignedByMe ? "assigned-by-me" : "assigned-to-me"
                }-markup`}
                to={`/p/${markup.project?.permalink}/viewer/markup/${markup.id}`}
                key={markup.id}
                className="p-4 border-0 border-b border-solid border-b-dark-900 no-underline transition hover:bg-dark-900"
              >
                <div className="flex items-center justify-between gap-1">
                  <div>
                    <p className="m-0 select-none">{markup.title}</p>
                    <p className="text-gray-500 text-xs">
                      Updated {dayjs(markup.updatedAt).fromNow()}
                    </p>
                  </div>

                  <div className="flex items-center gap-2">
                    <Badge
                      size="xs"
                      variant="light"
                      radius="sm"
                      color="dark"
                      className="bg-gray-800"
                    >
                      {markup?.project?.name}
                    </Badge>

                    <MarkupStatusBadge size="xs" value={markup.status} />

                    {query.assignedByMe ? (
                      <Tooltip
                        label={markup.assignee?.displayName || "No assignee"}
                      >
                        <Avatar size="sm" placeholder user={markup.assignee} />
                      </Tooltip>
                    ) : (
                      <Tooltip
                        label={markup.reporter?.displayName || "No reporter"}
                      >
                        <Avatar size="sm" placeholder user={markup.reporter} />
                      </Tooltip>
                    )}
                  </div>
                </div>
              </Link>
            ))
          ) : (
            <p className="m-4 text-dark-400">No markups yet...</p>
          )
        ) : (
          Array(3)
            .fill(0)
            .map((_, index) => <Skeleton key={index} visible height={64} />)
        )}
      </div>
    </ScrollArea.Autosize>
  );
};
