import { Project } from "@server/entities";
import React, { useState } from "react";
import { getProject, useProjects } from "../../../requests/project/project";
import { Search, SearchProps } from "@common/components";
import { Avatar } from "@mantine/core";

interface SearchProjectsProps
  extends Omit<SearchProps<Project>, "data" | "onChange" | "value"> {
  value: Project | null;
  onChange: (project: Project | null) => void;
}

export function SearchProjects({
  value,
  onChange,
  ...props
}: SearchProjectsProps) {
  const [search, setSearch] = useState("");
  const { data: projects, isValidating } = useProjects({
    query: search,
    limit: 5,
  });

  const handleChange = async (value: string) => {
    const project = projects?.data.find((project) => project.id === value);
    if (project) onChange(project);
    const { data } = await getProject(value);
    if (!data) return;
    onChange(data?.project);
  };

  const data =
    projects?.data.map((project) => ({
      label: project.name,
      value: project.id,
      data: project,
    })) || [];

  if (value) {
    const project = projects?.data.find((project) => project.id === value.id);
    if (!project) {
      data.push({
        label: value.name,
        value: value.id,
        data: value,
      });
    }
  }

  return (
    <Search<Project>
      loading={isValidating}
      name="search-projects"
      nothingFound="No projects found"
      placeholder="Search projects"
      data={data}
      onSearchChange={setSearch}
      renderOption={(option) => (
        <div
          data-testid={`project-option-${option.data.id}`}
          className={`relative flex items-center p-2`}
        >
          <Avatar
            src={option.data?.albumArt}
            className="mr-4"
            radius="sm"
            alt="albumArt"
            size={props.size}
          />

          <p className="m-0 overflow-hidden whitespace-nowrap text-ellipsis">
            {option.data.name}
          </p>
        </div>
      )}
      value={value?.id}
      onChange={handleChange}
      {...props}
    />
  );
}
