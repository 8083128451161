import React, { useState } from "react";
import {
  createMarkup,
  deleteMarkup,
} from "../../../../requests/project/markup";
import { useProject } from "../ProjectContext";
import Skeleton from "@common/components/Skeleton";
import {
  Avatar,
  Button,
  Card,
  Divider,
  DotsMenu,
  EmojiSelect,
  RichText,
} from "@common/components";
import useAsyncForm from "@common/utils/useAsyncForm";
import { CreateMarkupDto } from "@server/modules/project/markup/dto/create-markup.dto";
import { useUser } from "../../../../contexts/UserContext";
import {
  currTimeState,
  usePlayer,
  usePlayerState,
} from "../../../../contexts/Player";
import { getRecoil } from "../../../../contexts/RecoilNexus";
import dayjs from "dayjs";
import { Trash } from "tabler-icons-react";
import Confirm from "@common/components/Confirm";
import moment from "moment";
import { Badge } from "@mantine/core";
import { getRandomColor } from "@common/utils/get-random-color";
import { RichTextReadOnly } from "@common/components/RichTextReadOnly";

export const ProjectComments: React.FC<{ projectId: string }> = ({
  projectId,
}) => {
  const { user } = useUser();
  const { project, currMixdownId, markups, mutateMarkups } = useProject();
  const { seek } = usePlayer();
  const { playing } = usePlayerState(["playing"]);

  const comments = markups.filter((m) => m.type === "comment");

  const [delId, setDelId] = useState<string | null>(null);

  const form = useAsyncForm<CreateMarkupDto>({
    initialValues: {
      projectId,
      type: "comment",
    },
  });

  const handleSubmit = async () => {
    const currTime = getRecoil(currTimeState);

    const { error } = await form.sendForm((values) =>
      createMarkup({
        ...values,
        mixdownId: currMixdownId || undefined,
        start: playing ? currTime : undefined,
      })
    );

    if (error) return;
    form.setFieldValue("title", "");
    await mutateMarkups();
  };

  const handleDelete = async () => {
    if (!delId) return;
    await deleteMarkup(delId);
    setDelId(null);
    await mutateMarkups();
  };

  if (!project) return null;

  return (
    <div>
      <h2 className="mb-8">
        Comments{" "}
        {comments && (
          <span className="text-dark-500 ml-4">({comments.length})</span>
        )}
      </h2>

      {!comments ? (
        <Skeleton visible width="100%" height={100} />
      ) : (
        <Card>
          <div>
            {comments.length === 0 ? (
              <p className="text-dark-300">No comments yet</p>
            ) : (
              <div className="flex flex-col gap-6">
                {comments.map((comment) => (
                  <div key={comment.id} className="flex gap-4 items-start">
                    <Avatar user={comment.reporter} />

                    <div className="flex-1">
                      <p className="text-indigo-400 font-medium m-0">
                        {comment.reporter?.displayName}{" "}
                        <span className="text-dark-400 font-normal ml-1">
                          {dayjs(comment.createdAt).fromNow()}
                        </span>
                        {comment.mixdown && (
                          <Badge
                            className="ml-2"
                            size="xs"
                            color={getRandomColor(comment.mixdown.id)}
                          >
                            {comment.mixdown.name}
                          </Badge>
                        )}
                      </p>

                      <div>
                        {comment.start && (
                          <span
                            onClick={() => comment.start && seek(comment.start)}
                            className="bg-dark-500 text-indigo-400 text-xs font-medium px-1 py-0.5 rounded mr-2"
                          >
                            {moment()
                              .startOf("day")
                              .seconds(comment.start)
                              .format("m:ss")}
                          </span>
                        )}

                        <RichTextReadOnly value={comment.title} />
                      </div>
                    </div>

                    <DotsMenu>
                      <DotsMenu.Dropdown>
                        <DotsMenu.Item
                          color="red"
                          icon={<Trash className="w-4 h-4" />}
                          onClick={() => setDelId(comment.id)}
                        >
                          Delete comment
                        </DotsMenu.Item>
                      </DotsMenu.Dropdown>
                    </DotsMenu>
                  </div>
                ))}
              </div>
            )}
          </div>

          {user && (
            <div>
              <Divider className="my-6" />

              <div className="flex gap-4">
                <Avatar user={user} className="mt-4" />

                <RichText
                  {...form.getInputProps("title")}
                  users={project?.collaborators
                    .map((c) => c.user)
                    .filter((c) => c.id !== user?.id)}
                  classNames={{ toolbar: "hidden", root: "pr-28" }}
                  className="flex-1"
                  placeholder="Leave a comment"
                  minRows={1}
                  autosize
                />
              </div>

              <div className="flex justify-between mt-4 pl-16">
                <EmojiSelect
                  onEmojiClick={({ emoji }) => {
                    form.setFieldValue(
                      "title",
                      `${form.values.title || ""} ${emoji}`
                    );
                  }}
                />

                <Button onClick={handleSubmit} loading={form.loading}>
                  Comment
                </Button>
              </div>
            </div>
          )}
        </Card>
      )}

      <Confirm
        color="red"
        title="Delete comment"
        content="Are you sure you want to delete this comment?"
        icon={<Trash className="w-6 h-6" />}
        opened={!!delId}
        onClose={() => setDelId(null)}
        onConfirm={handleDelete}
      />
    </div>
  );
};
