import { notification } from "@common/utils/notification";
import { getRecoil, setRecoil } from "../../../../contexts/RecoilNexus";
import { Controller, PartialSetterOrUpdater } from "../helpers/controller";
import {
  deleteMarkup,
  getMarkup,
  getMarkups,
  revertMarkup,
  updateMarkup,
} from "../../../../requests/project/markup";
import { MarkupState } from "./markup.atom";
import { markupState } from "./markup.selector";
import { NavigateFunction } from "react-router-dom";
import { UpdateMarkupDto } from "@server/modules/project/markup/dto/update-markup.dto";
import { Markup, Stem } from "@server/entities/project";
import { uniqBy } from "lodash";

export class MarkupController extends Controller {
  private readonly _markup: () => MarkupState;
  private readonly setMarkup: PartialSetterOrUpdater<MarkupState>;

  get markup() {
    return this._markup();
  }

  constructor(private readonly navigate: NavigateFunction) {
    super();
    this._markup = () => getRecoil(markupState);
    this.setMarkup = (state) => setRecoil(markupState, state);
  }

  async fetch() {
    if (!this.state.track.project) return;
    const { data, error } = await getMarkups(this.state.track.project.id, {
      type: "markup",
    });
    if (error) return notification.error(error.message);
    return this.setMarkup({ markups: data?.data });
  }

  create(markup: Markup) {
    return this.setMarkup((state) => ({
      markups: uniqBy([...state.markups, markup], (markup) => markup.id),
    }));
  }

  view(id: string | null) {
    if (this.state.markup.viewLoading) return;

    const permalink = this.state.track.project?.permalink;

    if (!id) {
      permalink && this.navigate(`/p/${permalink}/viewer`);
      return this.setMarkup({ view: undefined });
    }

    permalink && this.navigate(`/p/${permalink}/viewer/markup/${id}`);

    const loadingView = this.markup.markups.find((markup) => markup.id === id);
    if (loadingView)
      this.setMarkup({
        view: loadingView,
        viewLoading: true,
      });

    return getMarkup(id).then(({ data, error }) => {
      if (!data) {
        this.view(null);
        permalink && this.navigate(`/p/${permalink}/viewer`);
        return notification.error(`${error?.message}`);
      }

      return this.setMarkup({
        view: data,
        viewLoading: false,
      });
    });
  }

  async update(id: string, updateMarkupDto: UpdateMarkupDto) {
    if (updateMarkupDto.stemId && !updateMarkupDto.stem) {
      updateMarkupDto.stem = this.state.stem.stems.find(
        (stem) => stem.id === updateMarkupDto.stemId
      ) as Stem;
    }

    this.setMarkup(({ markups }) => ({
      saving: true,
      markups: markups.map((markup) =>
        markup.id === id ? { ...markup, ...updateMarkupDto } : markup
      ),
      view: undefined,
    }));

    let { error } = await updateMarkup(id, updateMarkupDto);
    if (error) notification.error(error.message);
    this.setMarkup({ saving: false });
  }

  resize(id: string, { start, end }: Pick<Markup, "start" | "end">) {
    const markup = this.get(id);

    if (!markup) return;

    this.setMarkup(({ markups }) => ({
      markups: markups.map((markup) =>
        markup.id === id ? { ...markup, start, end } : markup
      ),
    }));
  }

  get(id: string) {
    return this.markup.markups.find((markup) => markup.id === id);
  }

  async revert(id: string) {
    const markup = this.get(id);

    if (!markup) return;

    const { error } = await revertMarkup(id);
    if (error) notification.error(error.message);

    this.view(null);
  }

  async approve(id: string) {
    const markup = this.get(id);

    if (!markup) return;

    const { error } = await updateMarkup(id, { status: "approved" });
    if (error) notification.error(error.message);

    this.view(null);
  }

  async delete(id: string) {
    const markup = this.get(id);

    if (!markup) return notification.error("Markup not found");

    const { error } = await deleteMarkup(markup.id);
    if (error) return notification.error(error.message);

    notification.success("Markup deleted");

    this.view(null);
    this.setMarkup(({ markups }) => ({
      markups: markups.filter((markup) => markup.id !== id),
    }));
  }
}
