import React, { useState } from "react";
import { Markup } from "@server/entities";
import { useHover } from "@use-gesture/react";
import { motion } from "framer-motion";
import { Avatar } from "@common/components";
import { getMarkupColor } from "../../../../stemviewer/view/markup/components/MarkupStatusBadge";
import { useMantineTheme } from "@mantine/core";
import { useTasks } from "../../../../../contexts/Tasks/TaskContext";
import { timeToPixels } from "@common/utils/time-pixel-converter";

interface MixdownMarkupProps {
  markup: Markup;
  duration: number;
  width: number;
}

export const MixdownMarkup: React.FC<MixdownMarkupProps> = ({
  markup,
  duration,
  width,
}) => {
  const { viewMarkup } = useTasks();
  const theme = useMantineTheme();
  const [hovered, setHovered] = useState(false);
  const bind = useHover(({ hovering }) => setHovered(!!hovering));

  if (!markup.start) return null;

  return (
    <>
      {markup.end && (
        <div
          data-testid="markup-region"
          className="opacity-0 md:opacity-100 absolute z-30 -top-2 h-[110%] transition"
          style={{
            background: hovered ? "rgba(255,46,46,0.6)" : "rgba(255,46,46,0.4)",
            left: timeToPixels(markup.start, width, duration) + 9,
            width: ((markup.end - markup.start) / duration) * (width - 16),
          }}
        />
      )}

      <div
        {...bind()}
        data-testid="sv-markup-bubble"
        data-quick-assist-id="sv-markup-bubble"
        className="absolute bottom-1/2 translate-y-1/2 cursor-pointer"
        onClick={() => viewMarkup(markup.id)}
        style={{
          left: timeToPixels(markup.end || markup.start, width, duration) + 9,
          zIndex: hovered ? 50 : 40,
        }}
      >
        <motion.div
          className="relative bg-dark-900 rounded-[2rem] rounded-bl-none overflow-hidden p-1"
          transition={{
            type: "tween",
            duration: 0.15,
          }}
          style={{
            border:
              markup.type === "markup"
                ? `1px solid ${theme.fn.rgba(
                    theme.colors[getMarkupColor(markup.status)][4],
                    0.6
                  )}`
                : `1px solid ${theme.colors.dark[9]}`,
          }}
        >
          <div className="flex items-center gap-3 h-full">
            <Avatar
              className="transition duration-[0.15s]"
              size="sm"
              user={markup.reporter}
            />

            {hovered && (
              <motion.div
                initial="collapsed"
                animate="open"
                exit="collapsed"
                variants={{
                  open: { opacity: 1, width: "auto", height: "auto" },
                  collapsed: { opacity: 0, width: 0, height: 0 },
                }}
                transition={{ type: "tween", duration: 0.15 }}
              >
                <div className="flex flex-col gap-1">
                  <p className="text-[1rem] max-w-[250px] pr-2 truncate">
                    {markup.title}
                  </p>
                </div>
              </motion.div>
            )}
          </div>
        </motion.div>
      </div>
    </>
  );
};
